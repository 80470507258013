<mat-select class="select {{ size }}"
            panelClass="app-multiselect-field-panel"
            multiple
            [class.fake-value]="showSelectedAllWithoutLoadedItems"
            [disableOptionCentering]="true"
            [placeholder]="showSelectedAllWithoutLoadedItems ? (inputPrefix + (allOptionLabel | translate)) : (placeholder | translate)"
            [disabled]="disabled"
            [class.invalid]="invalid"
            [(value)]="value"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (selectionChange)="onOptionSelected()">
  <mat-select-trigger>
    <span class="selected-text">
      {{ inputPrefix }}

      <ng-container *ngIf="!showValueAsAllWhenEveryOptionSelected || !isAllOptionsSelected || (!optionsWereLoadedAtLeastOnce && !autoselectAllAfterFirstLoad); else allText">
        <ng-container *ngIf="!showValueAsCounter">
           {{ selectedLabels | arrayJoin: { delimiter: ' / ' } }}
        </ng-container>

        <ng-container *ngIf="showValueAsCounter">
          {{ selectedLabels.length }}/{{ options.length }}
        </ng-container>
      </ng-container>
    </span>
  </mat-select-trigger>

  <mat-option *ngIf="showToggleAllOption" class="option option_all" disabled (click)="toggleAllOptions()">
    <mat-checkbox class="checkbox_toggle-all" [checked]="isAllOptionsSelected" disabled></mat-checkbox>

    <ng-container [ngTemplateOutlet]="allText"></ng-container>
  </mat-option>

  <mat-option *ngFor="let option of options; trackBy: trackByOption"
              [hidden]="loading"
              class="option"
              [value]="option[bindValue]">
    {{ translateLabel ? (option[bindLabel] | translate) : option[bindLabel]  }}
  </mat-option>

  <mat-option *ngIf="loading" class="option option_loading" disabled>
    <mat-spinner diameter="24"></mat-spinner>
  </mat-option>

  <mat-option *ngIf="!loading && !options?.length" class="option option_no-data" disabled>
    {{ 'commonNotifications.noResultsFound' | translate }}
  </mat-option>
</mat-select>

<ng-template #allText> {{ allOptionLabel | translate }} </ng-template>
