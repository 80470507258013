import { LocationPoint } from '@app/models/location-point';
import { UserWidget } from '@app/models/widgets.model';

export interface VisitDetailsVisitInfo {
  id: number;
  start_date_time: string;
  end_date_time: string;
  fact_start_date_time: string;
  fact_end_date_time: string;
  evv_start_date_time: string;
  evv_end_date_time: string;
  evv_start_date_time_method: VisitConfirmationType | null;
  evv_end_date_time_method: VisitConfirmationType | null;
  evv_start_location_point: LocationPoint | null;
  evv_end_location_point: LocationPoint | null;
  evv_start_location_address: string | null;
  evv_end_location_address: string | null;
  overtime_hours: number;
  overtime_minutes: number;
  verification_date_time: string;
  verification_type: number;
  verification_type_value: string;
  verification_by: number;
  verification_by_detail: UserWidget;
  notes: VisitNote[];
  plan_of_care: VisitDetailsPOC;
  ivr_phone_number: string;
  ivr_caregiver_identifier: string;
}

interface VisitNote {
  id?: number;
  reason: number;
  reason_detail?: DetialWidget;
  action: number;
  action_detail?: DetialWidget;
  text: string;
  user?: number;
  user_detail?: UserWidget;
  created_at?: string;
}

interface DetialWidget {
  id: number;
  value: string;
}

interface VisitDetailsPOC {
  plan_of_care_duties?: VisitDetailsPOCDuty[];
  plan_of_care_duties_data?: VisitDetailsPOCDuty[];
}

interface VisitDetailsPOCDuty {
  id?: number;
  duty: number;
  duty_details?: DutyDetails;
  result?: DutyResult;
  result_data?: DutyResult;
}

interface DutyDetails {
  id: number;
  category_name: string;
  status_value: string;
  name: string;
  price: string;
  duration: number;
  service_code: string;
  modifier: string;
  status: number;
  code: string;
  category: number;
}

interface DutyResult {
  status: number;
  status_value?: string;
}

export interface VisitDetailsDictionary {
  visit_note_reason: DetialWidget[];
  visit_note_action: DetialWidget[];
}

export interface VisitDetailsInfoUpdate {
  fact_start_date_time: string;
  fact_end_date_time: string;
  verification_date_time: string;
  verification_by: number;
  verification_type: number;
  overtime_hours: number;
  overtime_minutes: number;
  notes_data?: VisitNote[];
  plan_of_care_data?: VisitDetailsPOC;
}

export enum VisitConfirmationType {
  IVR,
  Application
}

export enum VisitType {
  Permanent = 0,
  Temporary = 1
}
