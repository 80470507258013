import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

import { flattenObject } from '@app/shared/helper';

export class TranslationData {
  constructor(public key: string, public interpolationObj?: object) {
  }
}

const ErrorConfig: Partial<IndividualConfig> = {
  positionClass: 'toast-top-left',
  timeOut: 10000,
};

const SuccessConfig: Partial<IndividualConfig> = {
  positionClass: 'toast-top-center',
  timeOut: 10000,
};

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  showSuccess(message: TranslationData | string, title = 'commonNotifications.success'): void {
    this.toastr.success(
      this.translate(message),
      this.translate(title),
      SuccessConfig
    );
  }

  showWarning(message: TranslationData | string, title?: string): void {
    this.toastr.warning(
      this.translate(message),
      this.translate(title),
      SuccessConfig
    );
  }

  showError(error: HttpErrorResponse | TranslationData | string, title?: TranslationData | string): void {
    let errorMessage: string;
    let errorTitle: string = title ? this.translate(title) : null;

    if (error instanceof HttpErrorResponse) {
      errorMessage = this.getResponseErrorMessage(error);
      errorTitle = <string | null>title ?? this.getResponseErrorTitle(error);
    } else if (error instanceof TranslationData || typeof error === 'string') {
      errorMessage = this.translate(error);
    } else {
      console.error('Unexpected error type: ', error);
      return;
    }

    this.toastr.error(errorMessage, errorTitle, ErrorConfig);
  }

  private getResponseErrorTitle(error: HttpErrorResponse): string {
   return `Error (${ error.statusText })`;
  }

  private getResponseErrorMessage(error: HttpErrorResponse): string {
    if (error.error && typeof error.error === 'object' && error.status !== 500) {
      return Object.values(flattenObject(error.error)).join('\n ');
    }

    return error.message ?? `Unknown error has occurred with status code ${ error.status }`;
  }

  private translate(data: TranslationData | string): string {
    if (data instanceof TranslationData) {
      return this.translateService.instant(data.key, data.interpolationObj);
    }

    if (typeof data === 'string') {
      return this.translateService.instant(data);
    }

    return '';
  }
}
