import { PatientWidget } from '@app/models/widgets.model';

export interface AvailableMasterweek {
  id: number;
  status: number;
  status_value: string;
  assign_status: AssignVisitStatus;
  assign_status_value: string;
  patient: number;
  patient_detail: PatientWidget;
  authorization: number;
  authorization_detail: MasterweekAuthorizationDetail;
  payer: number;
  payer_detail: MasterweekPayerDetail;
  start_date: string;
  end_date: string;
  services: MasterweekService[];
  hours_per_week: number;
  total_hours: number;
  visits_per_week: number;
  attentions: number[];
}

interface MasterweekAuthorizationDetail {
  id: number;
  code: string;
}

interface MasterweekPayerDetail {
  id: number;
  name: string;
}

interface MasterweekService {
  id: number;
  name: string;
}

export enum AssignVisitStatus {
  Unscheduled,
  Scheduled
}
