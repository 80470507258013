export const environment = {
  production: false,
  baseHost: 'cs.test3.caresync360.com',
  baseProtocol: 'https://',
  baseWsProtocol: 'wss://',
  basePath: '/api/v1/',
  basePort: '8081',
  baseWsPort: '8082',
  baseWsSecret: 'test',

  // Message Center
  mcHost: 'comm.test1.caresync360.com',
  mcPort: '8081',
  mcWsPort: '8082',
  mcWSSecret: 'test',

  googleApiKey: 'AIzaSyAOiE-bWqw9sz30T-L6cMuaEGIFY0JQBm8'
};
