import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  DispatchScreenService,
  FormService,
  NotificationsService,
  UserService,
  VisitDetailsService
} from '@app/core/services';
import {
  VisitConfirmationType,
  VisitDetailsInfoUpdate,
  VisitDetailsVisitInfo
} from '@app/models/patient/visit-details/visit.model';
import { UserType } from '@app/models/users/user-profile.model';
import { VisitTabFormComponent } from '@app/pages/visit-details/models';
import { pad, unsubscribe } from '@app/shared/helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment/moment';
import { forkJoin, Subscription } from 'rxjs';
import { VisitStatus } from '@app/models/visits/visits.model';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-visit-tab',
  templateUrl: './visit-tab.component.html',
  styleUrls: ['./visit-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisitTabComponent implements VisitTabFormComponent, OnInit, OnDestroy {
  @Input() visitId: number;
  @Input() visitStatus: number;

  isLoading: boolean;
  visitData: VisitDetailsVisitInfo;
  form: FormGroup;
  isSaving: boolean;
  readonly visitStatusesKeys = VisitStatus;

  supervisorList: { value: number, label: string }[] = [];
  verifiedList = [
    { value: 0, label: 'Patient' },
    { value: 1, label: 'Caregiver' }
  ];

  notesList = [];
  newNotesList = [];
  reasonList = [];
  actionTakenList = [];
  pocList = [];
  showAddNotesBtns: boolean = false;
  visitsDuration: string;
  reasonId: number;
  actionTakenId: number;
  resetOverTime: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private visitDetailsService: VisitDetailsService,
    private formService: FormService,
    private userService: UserService,
    private dispatchScreenService: DispatchScreenService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
  ) { }

  get visitStart(): FormControl {
    return this.form.get('visitStart') as FormControl;
  }
  get visitEnd(): FormControl {
    return this.form.get('visitEnd') as FormControl;
  }
  get overtimeHours(): FormControl {
    return this.form.get('overtimeHours') as FormControl;
  }
  get overtimeMinutes(): FormControl {
    return this.form.get('overtimeMinutes') as FormControl;
  }
  get timeVerified(): FormControl {
    return this.form.get('timeVerified') as FormControl;
  }
  get notes(): FormControl {
    return this.form.get('notes') as FormControl;
  }
  get actionTaken(): FormControl {
    return this.form.get('action_taken') as FormControl;
  }
  get reason(): FormControl {
    return this.form.get('reason') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadVisitData();
    this.loadSelectOptions();

    this.subscriptions.push(
      this.visitDetailsService.getRefreshCurrentTab
      .subscribe((response: boolean) => {
        if (response) {
          this.loadVisitData();
        }
      }),
      this.visitDetailsService.getUpdatedVisitTab
      .subscribe((response: VisitDetailsVisitInfo) => {
        if (response) {
          this.visitData = response;
          this.updateForm(response);
        }
      }),
    );
  }

  private loadSelectOptions(): void {
    const usersQuery = {
      profile_types: [UserType.Administrator, UserType.Coordinator],
      widget: 'm2m',
      limit: 1000
    };
    forkJoin([
      this.userService.getUsers(usersQuery),
      this.visitDetailsService.getDictionary('visit_note_reason,visit_note_action')
    ])
      .subscribe(([supervisors, dictionary]) => {
        this.supervisorList = supervisors.results.map(el => ({ value: el.id, label: el.full_name }));
        this.reasonList = dictionary.visit_note_reason;
        this.actionTakenList = dictionary.visit_note_action;
        this.cdr.detectChanges();
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      visitStart: null,
      visitEnd: null,
      overtimeHours: null,
      overtimeMinutes: null,
      supervisor: null,
      verified_by: null,
      timeVerified: null,
      notes: '',
      reason: null,
      action_taken: null
    });
  }

  private loadVisitData(): void {
    this.isLoading = true;

    if (this.visitData) {
      this.visitData = undefined;
      this.cdr.detectChanges();
    }

    this.visitDetailsService.getVisitInfo(this.visitId)
    .subscribe((response: VisitDetailsVisitInfo) => {
      this.isLoading = false;
      this.visitData = response;
      this.updateForm(response);
    },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.formService.nonFieldErrors(error);
        this.cdr.detectChanges();
      });
  }

  private updateForm(response: VisitDetailsVisitInfo): void {
    this.notesList = response.notes.map(n => {
      return {
        date: n.created_at,
        reason_id: n.reason,
        reason: n.reason_detail.value,
        action_taken_id: n.action,
        action_taken: n.action_detail.value,
        notes: n.text,
        user: n.user_detail.full_name
      };
    });

    this.pocList = response.plan_of_care.plan_of_care_duties.map(d => {
      return {
        id: d.id,
        duty: d.duty,
        categoty: d.duty_details.category,
        categoryName: d.duty_details.category_name,
        dutyName: d.duty_details.name,
        minutes: d.duty_details.duration,
        done: !!d.result?.status,
        not_done: !d.result?.status
      };
    });

    const sum = this.pocList.reduce((sum, poc) => {
      sum += poc.minutes;
      return sum;
    }, 0);
    this.visitsDuration = this.getTimeFromMins(sum);

    this.form.patchValue({
      visitStart: response.fact_start_date_time,
      visitEnd: response.fact_end_date_time,
      overtimeHours: +response.overtime_hours === 0 ? '00' : pad(response.overtime_hours),
      overtimeMinutes: +response.overtime_minutes === 0 ? '00' : pad(response.overtime_minutes),
      supervisor: response.verification_by,
      verified_by: response.verification_type,
      timeVerified: response.verification_date_time
    });

    this.cdr.detectChanges();
  }

  verificationDateChange(event): void {
    this.timeVerified.markAsDirty();
    this.form.patchValue({
      timeVerified: event
    });
  }

  private setPayloadForSave(): VisitDetailsInfoUpdate {
    const form = this.form.value;

    const pocData = this.pocList?.map(poc => {
      return {
        id: poc.id,
        duty: poc.duty,
        result_data: {
          status: poc.done ? 1 : 0
        }
      };
    });

    let overtimeHours = form.overtimeHours;
    let overtimeMinutes = form.overtimeMinutes;
    if (this.resetOverTime) {
      overtimeHours = '00';
      overtimeMinutes = '00';
    }
    return {
      fact_start_date_time: form.visitStart,
      fact_end_date_time: form.visitEnd,
      verification_date_time: form.timeVerified,
      verification_by: form.supervisor,
      verification_type: form.verified_by,
      overtime_hours: overtimeHours,
      overtime_minutes: overtimeMinutes,
      notes_data: this.newNotesList,
      plan_of_care_data: {
        plan_of_care_duties_data: pocData || []
      }
    };
  }

  save(onSuccess = () => null): void {
    this.isSaving = true;
    this.cdr.markForCheck();

    this.visitDetailsService.updateVisitInfo(this.visitId, this.setPayloadForSave())
      .pipe(finalize(() => this.isSaving = false), untilDestroyed((this)))
      .subscribe((response: VisitDetailsVisitInfo) => {
        this.newNotesList = [];
        this.form.markAsPristine();
        this.loadVisitData();
        this.notificationsService.showSuccess('visitDetails.tabVisit_action_update-success');
        onSuccess();
      },
      (error: HttpErrorResponse) => {
        this.formService.nonFieldErrors(error);
        this.cdr.detectChanges();
      });
  }

  setStatusAsInProgress(): void {
    this.changeStatus(VisitStatus.inProgress);
  }

  setStatusAsCompleted(): void {
    this.changeStatus(VisitStatus.completed);
  }

  private changeStatus(status: VisitStatus): void {
    this.dispatchScreenService.changeStatus(this.visitData.id, { status })
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (error) => this.formService.nonFieldErrors(error),
      });
  }

  addNotes(): void {
    const reasonValue = this.reasonList.find(r => r.id === this.reason.value).value;
    const actionTakenValue = this.actionTakenList.find(a => a.id === this.actionTaken.value).value;

    this.notesList.unshift({
      date: moment(new Date()).format('MM/DD/YYYY HH:mm'),
      reason: reasonValue,
      action_taken: actionTakenValue,
      notes: this.notes.value
    });
    this.newNotesList.push({
      reason: this.reasonId,
      action: this.actionTakenId,
      text: this.notes.value,
    });

    this.cancelNotes();
  }

  cancelNotes(): void {
    this.reason.reset();
    this.actionTaken.reset();
    this.notes.reset();
    this.showAddNotesBtns = false;
  }

  changeReason(reasonId: number): void {
    this.reasonId = reasonId;
    this.checkNotes();
  }

  changeActionsTaken(actionId: number): void {
    this.actionTakenId = actionId;
    this.checkNotes();
  }

  checkNotes(): void {
    this.showAddNotesBtns = !!(this.reason.value && this.actionTaken.value && this.notes.value);
  }

  changePOCCheckbox(event, pocId: number, fieldName: string): void {
    const poc = this.pocList.find(poc => poc.id === pocId);
    if (fieldName === 'done') {
      poc.done = event;
      poc.not_done = !event;
    } else {
      poc.done = !event;
      poc.not_done = event;
    }
  }

  visitStartTimeChange(event: string): void {
    this.visitStart.markAsDirty();
    this.visitStart.setValue(event);
    this.resetOverTime = true;
  }

  visitEndTimeChange(event: string): void {
    this.visitEnd.markAsDirty();
    this.visitEnd.setValue(event);
    this.resetOverTime = true;
  }

  getTimeFromMins(mins): string {
    let hours = Math.trunc(mins / 60);
    let minutes = mins % 60;
    return hours + ':' + minutes;
  }

  doubleNumber(time: number): string {
    return pad(time);
  }

  getEvvIconName(visitResponseType: VisitConfirmationType): string {
    return visitResponseType === VisitConfirmationType.Application ? 'phone-android' : 'call-24px';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => unsubscribe(subscription));
  }
}
