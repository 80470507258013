export enum GeneralStatus {
  AcceptingOffer,
  Active,
  Available,
  Cancelled,
  Closed,
  Completed,
  Coordinated,
  DeclinedOffer,
  EnRoute,
  Expired,
  Inactive,
  InProgress,
  Missed,
  NotCompleted,
  Offered,
  Open,
  PendingOffer,
  Scheduled,
  Unscheduled
}