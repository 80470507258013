import { GeneralStatus } from '@app/core/enums';
import { PatientStatus } from '@app/models/patient/patient.model';
import { StatusesMapper } from '@app/models/statuses-mapper.model';

export const PATIENT_STATUSES_MAPPER: StatusesMapper = {
  [PatientStatus.Active]: GeneralStatus.Active,
  [PatientStatus.Hold]: null,
  [PatientStatus.Discharged]: null,
  [PatientStatus.HospitalizedObservation]: null,
  [PatientStatus.HospitalizedAdmitted]: null,
  [PatientStatus.PatientNeverStarted]: null
}